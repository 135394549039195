import * as actionTypes from './types';

const initialState = {
    loading: false,
    error: false,
    image_sliders: [],
    image_slider: undefined,
};
const imageSliderReducer = (state = { ...initialState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.TOGGLE_IS_FEATURE_IMAGE_SLIDER_REQUEST:
        case actionTypes.DELETE_IMAGE_SLIDER_REQUEST:
        case actionTypes.UPDATE_IMAGE_SLIDER_REQUEST:
        case actionTypes.CREATE_NEW_IMAGE_SLIDER_REQUEST:
        case actionTypes.GET_ALL_IMAGE_SLIDERS_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.TOGGLE_IS_FEATURE_IMAGE_SLIDER_SUCCESS:
        case actionTypes.DELETE_IMAGE_SLIDER_SUCCESS:
        case actionTypes.UPDATE_IMAGE_SLIDER_SUCCESS:
        case actionTypes.CREATE_NEW_IMAGE_SLIDER_SUCCESS:
        case actionTypes.GET_ALL_IMAGE_SLIDERS_SUCCESS: {
            return {
                ...state,
                image_sliders: payload,
                loading: false,
                error: false
            };
        }
            
        case actionTypes.GET_IMAGE_SLIDERS_ID_SUCCESS: {
            return {
                ...state,
                image_slider: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.TOGGLE_IS_FEATURE_IMAGE_SLIDER_FAILED:
        case actionTypes.DELETE_IMAGE_SLIDER_FAILED:
        case actionTypes.UPDATE_IMAGE_SLIDER_FAILED:
        case actionTypes.CREATE_NEW_IMAGE_SLIDER_FAILED:
        case actionTypes.GET_ALL_IMAGE_SLIDERS_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default imageSliderReducer;
