import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const NotAllowed = Loadable(lazy(() => import('views/NotAllowed')));
const RoleDetales = Loadable(lazy(() => import('views/roles/RoleDetales')));
const AllRoles = Loadable(lazy(() => import('views/roles/AllRoles')));
const AddNewRole = Loadable(lazy(() => import('views/roles/AddNewRole')));
const AllUsers = Loadable(lazy(() => import('views/Users/AllUsers')));
const EditUser = Loadable(lazy(() => import('views/Users/EditUser')));
const AddNewUser = Loadable(lazy(() => import('views/Users/AddNewUser')));
const AllLanguages = Loadable(lazy(() => import('views/language/AllLanguages')));
const EditLanguage = Loadable(lazy(() => import('views/language/EditLanguage')));
const AddNewLanguage = Loadable(lazy(() => import('views/language/AddNewLanguage')));
const EditMyAccount = Loadable(lazy(() => import('views/Users/EditMyAccount')));
const ResetPassword = Loadable(lazy(() => import('views/Users/ResetPassword')));
const ResetMyPassword = Loadable(lazy(() => import('views/Users/ResetMyPassword')));

//company-profile
const EditCompanyProfile = Loadable(lazy(() => import('views/Content/EditCompanyProfile')));

// content
const EditAboutUs = Loadable(lazy(() => import('views/Content/EditAboutUs')));
const EditContactUs = Loadable(lazy(() => import('views/Content/EditContactUs')));
const EditPrivacyPolicy = Loadable(lazy(() => import('views/Content/EditPrivacyPolicy')));
const EditCookiePolicy = Loadable(lazy(() => import('views/Content/EditCookiePolicy')));
const EditTermsUse = Loadable(lazy(() => import('views/Content/EditTermsUse')));

// service
const AllServices = Loadable(lazy(() => import('views/Service/AllServices')));
const AddNewService = Loadable(lazy(() => import('views/Service/AddNewService')));
const EditService = Loadable(lazy(() => import('views/Service/EditService')));

// image slider
const AllImageSliders = Loadable(lazy(() => import('views/ImageSlider/AllImageSliders')));
const AddNewImageSlider = Loadable(lazy(() => import('views/ImageSlider/AddNewImageSlider')));
const EditImageSlider = Loadable(lazy(() => import('views/ImageSlider/EditImageSlider')));

// category
const AllCategories = Loadable(lazy(() => import('views/category/all-categories')));
const AddNewCategory = Loadable(lazy(() => import('views/category/add-new-category')));
const EditCategory = Loadable(lazy(() => import('views/category/edit-category')));

// color
const AllColors = Loadable(lazy(() => import('views/color/all-colors')));
const AddNewColor = Loadable(lazy(() => import('views/color/add-new-color')));
const EditColor = Loadable(lazy(() => import('views/color/edit-color')));

// product
const AllProducts = Loadable(lazy(() => import('views/product/AllProduct')));
const AddNewProduct = Loadable(lazy(() => import('views/product/AddNewProduct')));
const EditProduct = Loadable(lazy(() => import('views/product/EditProduct')));
const ViewColor = Loadable(lazy(() => import('views/product/ViewColor')));

// client
const AllClients = Loadable(lazy(() => import('views/Client/AllClients')));
const AddNewClient = Loadable(lazy(() => import('views/Client/AddNewClient')));
const EditClient = Loadable(lazy(() => import('views/Client/EditClient')));

//contactUs
const AllContactUs=Loadable(lazy(() => import('views/Client/EditClient')));
// setting

// contact us messages (sales manager)
const MyAllContactUsMessages = Loadable(lazy(() => import('views/ContactUsMessages/MyAllContactUsMessages')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: '/not-allowed',
            element: <NotAllowed />
        },
        {
            path: '/Roles',
            element: <AllRoles />
        },
        {
            path: '/Roles/add',
            element: <AddNewRole />
        },
        {
            path: '/Roles/:role_id',
            element: <RoleDetales />
        },
        {
            path: '/Languages',
            element: <AllLanguages />
        },
        {
            path: '/Languages/:Language_id',
            element: <EditLanguage />
        },
        {
            path: '/Languages/add',
            element: <AddNewLanguage />
        },

        {
            path: '/Users',
            element: <AllUsers />
        },
        {
            path: '/Users/:user_id',
            element: <EditUser />
        },
        {
            path: '/Users/:user_id/ResetPassword',
            element: <ResetPassword />
        },
        {
            path: '/EditMyAccount',
            element: <EditMyAccount />
        },
        {
            path: '/EditMyAccount/ResetPassword',
            element: <ResetMyPassword />
        },
        {
            path: '/Users/Add',
            element: <AddNewUser />
        },

        {
            path: '/sample-page',
            element: <SamplePage />
        },

        {
            path: '/about-us/edit',
            element: <EditAboutUs />
        },
         {
            path: '/company-profile/edit',
            element: <EditCompanyProfile />
        },
        {
            path: '/contact-us/edit',
            element: <EditContactUs />
        },
        {
            path: '/privacy-policy/edit',
            element: <EditPrivacyPolicy />
        },
        {
            path: '/cookie-policy/edit',
            element: <EditCookiePolicy />
        },
        {
            path: '/terms-use/edit',
            element: <EditTermsUse />
        },
        {
            path: '/services',
            element: <AllServices />
        },
        {
            path: '/services/add',
            element: <AddNewService />
        },
        {
            path: '/services/:service_id',
            element: <EditService />
        },
        {
            path: '/image-sliders',
            element: <AllImageSliders />
        },
        {
            path: '/image-sliders/add',
            element: <AddNewImageSlider />
        },
        {
            path: '/image-sliders/:image_slider_id',
            element: <EditImageSlider />
        },
        {
            path: '/categories',
            element: <AllCategories />
        },
        {
            path: '/categories/add',
            element: <AddNewCategory />
        },
        {
            path: '/categories/:category_id',
            element: <EditCategory />
        },
        {
            path: '/colors',
            element: <AllColors />
        },
        {
            path: '/colors/add',
            element: <AddNewColor />
        },
        {
            path: '/colors/:color_id',
            element: <EditColor />
        },
        {
            path: '/messages',
            element: <MyAllContactUsMessages />
        },
        {
            path: '*',
            element: <h1>This page not found</h1>
        },
        {
            path: '/products',
            element: <AllProducts />
        },
        {
            path: '/products/add',
            element: <AddNewProduct />
        },
        {
            path: '/products/:product_id',
            element: <EditProduct />
        },
         {
            path: '/products/viewColor/:product_id',
            element: <ViewColor />
        },
        {
            path: '/clients',
            element: <AllClients />
        },
        {
            path: '/clients/add',
            element: <AddNewClient />
        },
        {
            path: '/clients/:client_id',
            element: <EditClient />
        },
    ]
};

export default MainRoutes;
