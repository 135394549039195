import * as actionTypes from './types';

const initialState = {
    loading: false,
    error: false,
    colors: [],
    color: undefined
};
const colorsReducer = (state = { ...initialState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.TOGGLE_IS_ENABLED_COLOR_REQUEST:
        case actionTypes.DELETE_COLOR_REQUEST:
        case actionTypes.UPDATE_COLOR_REQUEST:
        case actionTypes.CREATE_NEW_COLOR_REQUEST:
        case actionTypes.GET_ALL_COLORS_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.TOGGLE_IS_ENABLED_COLOR_SUCCESS:
        case actionTypes.DELETE_COLOR_SUCCESS:
        case actionTypes.UPDATE_COLOR_SUCCESS:
        case actionTypes.CREATE_NEW_COLOR_SUCCESS:
        case actionTypes.GET_ALL_COLORS_SUCCESS: {
            return {
                ...state,
                colors: payload,
                loading: false,
                error: false
            };
        }

        case actionTypes.GET_COLOR_ID_SUCCESS: {
            return {
                ...state,
                color: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.TOGGLE_IS_ENABLED_COLOR_FAILED:
        case actionTypes.DELETE_COLOR_FAILED:
        case actionTypes.UPDATE_COLOR_FAILED:
        case actionTypes.CREATE_NEW_COLOR_FAILED:
        case actionTypes.GET_ALL_COLORS_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default colorsReducer;
