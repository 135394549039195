export const GET_ALL_COLORS_REQUEST = 'GET_ALL_COLORS_REQUEST';
export const GET_ALL_COLORS_SUCCESS = 'GET_ALL_COLORS_SUCCESS';
export const GET_COLOR_ID_SUCCESS = 'GET_COLOR_ID_SUCCESS';
export const GET_ALL_COLORS_FAILED = 'GET_ALL_COLORS_FAILED';

export const CREATE_NEW_COLOR_REQUEST = 'CREATE_NEW_COLOR_REQUEST';
export const CREATE_NEW_COLOR_SUCCESS = 'CREATE_NEW_COLOR_SUCCESS';
export const CREATE_NEW_COLOR_FAILED = 'CREATE_NEW_COLOR_FAILED';

export const UPDATE_COLOR_REQUEST = 'UPDATE_COLOR_REQUEST';
export const UPDATE_COLOR_SUCCESS = 'UPDATE_COLOR_SUCCESS';
export const UPDATE_COLOR_FAILED = 'UPDATE_COLOR_FAILED';

export const TOGGLE_IS_ENABLED_COLOR_REQUEST = 'TOGGLE_IS_ENABLED_COLOR_REQUEST';
export const TOGGLE_IS_ENABLED_COLOR_SUCCESS = 'TOGGLE_IS_ENABLED_COLOR_SUCCESS';
export const TOGGLE_IS_ENABLED_COLOR_FAILED = 'TOGGLE_IS_ENABLED_COLOR_FAILED';

export const DELETE_COLOR_REQUEST = 'DELETE_COLOR_REQUEST';
export const DELETE_COLOR_SUCCESS = 'DELETE_COLOR_SUCCESS';
export const DELETE_COLOR_FAILED = 'DELETE_COLOR_FAILED';
