import { authAxios } from 'config/axios';
import { ADD_NEW_ROLE, DELETE_ROLE, GET_ALL_ROLES, UPDATE_ROLE } from 'env/server';
import * as actionTypes from './types';
export const successRole = (res) => {
    return {
        type: actionTypes.SUCCESS_ROLE,
        payload: res
    };
};

export const requestRole = () => {
    return {
        type: actionTypes.REQUST_ROLE
    };
};
export const errorRole = (error) => {
    

    // const message = getErrorMessage(error);
    const message = 'There is error';
    return {
        type: actionTypes.FAILED_ROLE,
        payload: message
    };
};
export const getAllRoles = () => {
    return (dispatch) => {
        dispatch(requestRole());
        authAxios
            .get(GET_ALL_ROLES)
            .then((res) => {
                const roles=res.data
                dispatch(successRole({roles}))
            })
            .catch((error) => {
                dispatch(errorRole(error));

                //////////////to remove/////////////////to remove//////////////
                //////////////to remove/////////////////to remove//////////////
            });
    };
};
export const EditRole = (id,values,sucsessFeedback) => {
    return (dispatch) => {
        dispatch(requestRole());
        authAxios
            .put(`${UPDATE_ROLE}/${id}`,values)
            .then((res) => {
                const roles=res.data
                dispatch(successRole({}))
                sucsessFeedback()
            })
            .catch((error) => {
                dispatch(errorRole(error));

                //////////////to remove/////////////////to remove//////////////
                //////////////to remove/////////////////to remove//////////////
            });
    };
};
export const deleteRole = (id) => {
    return (dispatch) => {
        dispatch(requestRole());
        authAxios
            .delete(`${DELETE_ROLE}/${id}`)
            .then((res) => {
                const roles=res.data
                dispatch(successRole({roles}))
            })
            .catch((error) => {
                dispatch(errorRole(error));

                //////////////to remove/////////////////to remove//////////////
                //////////////to remove/////////////////to remove//////////////
            });
    };
};
export const addNewRole = (values,sucsessFeedback) => {
    return (dispatch) => {
        dispatch(requestRole());
        authAxios
            .post(`${ADD_NEW_ROLE}`,values)
            .then((res) => {
                const roles=res.data
                dispatch(successRole())
                sucsessFeedback()
            })
            .catch((error) => {
                dispatch(errorRole(error));


                //////////////to remove/////////////////to remove//////////////
                //////////////to remove/////////////////to remove//////////////
            });
    };
};
