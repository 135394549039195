import axios from 'axios';
import { authAxios } from 'config/axios';
import {
    ADD_NEW_ACCOUNT,
    GET_ALL_ACCOUNTS,
    GET_ACCOUNT_BY_ID,
    UPDATE_ACCOUNT,
    DELETE_ACCOUNT,
    RESET_ACCOUNT_PASSWORD,
    TOGGLE_IS_ACCTIVE_ACCOUNT,
    RESET_MY_PASSWORD
} from 'env/server';
import * as actionTypes from './types';
export const successAccountMamgment = (res) => {
    return {
        type: actionTypes.SUCCESS_MANGMENT_ACCOUNT,
        payload: res
    };
};

export const requestAccountMamgment = () => {
    return {
        type: actionTypes.REQUST_MANGMENT_ACCOUNT
    };
};
export const errorAccountMamgment = (error) => {
    // const message = getErrorMessage(error);
    const message = 'There is error';
    return {
        type: actionTypes.FAILED_MANGMENT_ACCOUNT,
        payload: message
    };
};

export const addNewAccount = (values, successFeadBack) => {
   
    return (dispatch) => {
        dispatch(requestAccountMamgment());
        authAxios
            .post(ADD_NEW_ACCOUNT, values)
            .then((res) => {
                dispatch(successAccountMamgment());
                successFeadBack();
            })
            .catch((error) => {
                dispatch(errorAccountMamgment(error));
            });
    };
};
export const getAllAccounts = () => {
    return (dispatch) => {
        dispatch(requestAccountMamgment());
        authAxios
            .get(GET_ALL_ACCOUNTS)
            .then((res) => {
                const accounts = res.data;
                dispatch(successAccountMamgment({ accounts }));
            })
            .catch((error) => {
                dispatch(errorAccountMamgment(error));
            });
    };
};

export const updateAccount = (id, values, successFeadBacke) => {
    return (dispatch) => {
        dispatch(requestAccountMamgment());
        authAxios
            .put(`${UPDATE_ACCOUNT}/${id}`, values)
            .then((res) => {
                successFeadBacke();
                const accounts = res.data;
                dispatch(successAccountMamgment({ accounts }));
            })
            .catch((error) => {
                dispatch(errorAccountMamgment(error));
            });
    };
};
export const deleteAccount = (id) => {
    return (dispatch) => {
        dispatch(requestAccountMamgment());
        authAxios
            .delete(`${DELETE_ACCOUNT}/${id}`)
            .then((res) => {
                const accounts = res.data;
          
                dispatch(successAccountMamgment({ accounts }));
            })
            .catch((error) => {
                dispatch(errorAccountMamgment(error));
            });
    };
};
export const resetPassword = (id, values, sucsessFeedback) => {
    return (dispatch) => {
        dispatch(requestAccountMamgment());
        authAxios
            .put(`${RESET_ACCOUNT_PASSWORD}/${id}`, values)
            .then((res) => {
                const account = res.data;

                
                dispatch(successAccountMamgment());
                sucsessFeedback();
            })
            .catch((error) => {
                dispatch(errorAccountMamgment(error));
            });
    };
};
export const toggleAccountActivete = (id) => {
    return (dispatch) => {
        dispatch(requestAccountMamgment());
        authAxios
            .put(`${TOGGLE_IS_ACCTIVE_ACCOUNT}/${id}`)
            .then((res) => {
                const accounts = res.data;
                dispatch(successAccountMamgment({ accounts }));
            })
            .catch((error) => {
                dispatch(errorAccountMamgment(error));
            });
    };
};
