export const GET_ABOUT_US_CONTENT_REQUEST = 'GET_ABOUT_US_CONTENT_REQUEST';
export const GET_ABOUT_US_CONTENT_SUCCESS = 'GET_ABOUT_US_CONTENT_SUCCESS';
export const GET_ABOUT_US_CONTENT_FAILED = 'GET_ABOUT_US_CONTENT_FAILED';

export const UPDATE_ABOUT_US_CONTENT_REQUEST = 'UPDATE_ABOUT_US_CONTENT_REQUEST';
export const UPDATE_ABOUT_US_CONTENT_SUCCESS = 'UPDATE_ABOUT_US_CONTENT_SUCCESS';
export const UPDATE_ABOUT_US_CONTENT_FAILED = 'UPDATE_ABOUT_US_CONTENT_FAILED';

export const GET_COMPANY_PROFILE_REQUEST = 'GET_COMPANY_PROFILE_REQUEST';
export const GET_COMPANY_PROFILE_SUCCESS = 'GET_COMPANY_PROFILE_SUCCESS';
export const GET_COMPANY_PROFILE_FAILED = 'GET_COMPANY_PROFILE_FAILED';

export const UPDATE_COMPANY_PROFILE_REQUEST = 'UPDATE_COMPANY_PROFILE_REQUEST';
export const UPDATE_COMPANY_PROFILE_SUCCESS = 'UPDATE_COMPANY_PROFILE_SUCCESS';
export const UPDATE_COMPANY_PROFILE_FAILED = 'UPDATE_COMPANY_PROFILE_FAILED';

export const GET_CONTACT_US_CONTENT_REQUEST = 'GET_CONTACT_US_CONTENT_REQUEST';
export const GET_CONTACT_US_CONTENT_SUCCESS = 'GET_CONTACT_US_CONTENT_SUCCESS';
export const GET_CONTACT_US_CONTENT_FAILED = 'GET_CONTACT_US_CONTENT_FAILED';

export const UPDATE_CONTACT_US_CONTENT_REQUEST = 'UPDATE_CONTACT_US_CONTENT_REQUEST';
export const UPDATE_CONTACT_US_CONTENT_SUCCESS = 'UPDATE_CONTACT_US_CONTENT_SUCCESS';
export const UPDATE_CONTACT_US_CONTENT_FAILED = 'UPDATE_CONTACT_US_CONTENT_FAILED';

export const GET_PRIVACY_POLICY_CONTENT_REQUEST = 'GET_PRIVACY_POLICY_CONTENT_REQUEST';
export const GET_PRIVACY_POLICY_CONTENT_SUCCESS = 'GET_PRIVACY_POLICY_CONTENT_SUCCESS';
export const GET_PRIVACY_POLICY_CONTENT_FAILED = 'GET_PRIVACY_POLICY_CONTENT_FAILED';

export const UPDATE_PRIVACY_POLICY_CONTENT_REQUEST = 'UPDATE_PRIVACY_POLICY_CONTENT_REQUEST';
export const UPDATE_PRIVACY_POLICY_CONTENT_SUCCESS = 'UPDATE_PRIVACY_POLICY_CONTENT_SUCCESS';
export const UPDATE_PRIVACY_POLICY_CONTENT_FAILED = 'UPDATE_PRIVACY_POLICY_CONTENT_FAILED';

export const GET_COOKIE_POLICY_CONTENT_REQUEST = 'GET_COOKIE_POLICY_CONTENT_REQUEST';
export const GET_COOKIE_POLICY_CONTENT_SUCCESS = 'GET_COOKIE_POLICY_CONTENT_SUCCESS';
export const GET_COOKIE_POLICY_CONTENT_FAILED = 'GET_COOKIE_POLICY_CONTENT_FAILED';

export const UPDATE_COOKIE_POLICY_CONTENT_REQUEST = 'UPDATE_COOKIE_POLICY_CONTENT_REQUEST';
export const UPDATE_COOKIE_POLICY_CONTENT_SUCCESS = 'UPDATE_COOKIE_POLICY_CONTENT_SUCCESS';
export const UPDATE_COOKIE_POLICY_CONTENT_FAILED = 'UPDATE_COOKIE_POLICY_CONTENT_FAILED';

export const GET_LEGAL_NOTICES_DISCLAIMER_CONTENT_REQUEST = 'GET_LEGAL_NOTICES_DISCLAIMER_CONTENT_REQUEST';
export const GET_LEGAL_NOTICES_DISCLAIMER_CONTENT_SUCCESS = 'GET_LEGAL_NOTICES_DISCLAIMER_CONTENT_SUCCESS';
export const GET_LEGAL_NOTICES_DISCLAIMER_CONTENT_FAILED = 'GET_LEGAL_NOTICES_DISCLAIMER_CONTENT_FAILED';

export const UPDATE_LEGAL_NOTICES_DISCLAIMER_CONTENT_REQUEST = 'UPDATE_LEGAL_NOTICES_DISCLAIMER_CONTENT_REQUEST';
export const UPDATE_LEGAL_NOTICES_DISCLAIMER_CONTENT_SUCCESS = 'UPDATE_LEGAL_NOTICES_DISCLAIMER_CONTENT_SUCCESS';
export const UPDATE_LEGAL_NOTICES_DISCLAIMER_CONTENT_FAILED = 'UPDATE_LEGAL_NOTICES_DISCLAIMER_CONTENT_FAILED';

export const GET_TERMS_USE_CONTENT_REQUEST = 'GET_TERMS_USE_CONTENT_REQUEST';
export const GET_TERMS_USE_CONTENT_SUCCESS = 'GET_TERMS_USE_CONTENT_SUCCESS';
export const GET_TERMS_USE_CONTENT_FAILED = 'GET_TERMS_USE_CONTENT_FAILED';

export const UPDATE_TERMS_USE_CONTENT_REQUEST = 'UPDATE_TERMS_USE_CONTENT_REQUEST';
export const UPDATE_TERMS_USE_CONTENT_SUCCESS = 'UPDATE_TERMS_USE_CONTENT_SUCCESS';
export const UPDATE_TERMS_USE_CONTENT_FAILED = 'UPDATE_TERMS_USE_CONTENT_FAILED';

export const GET_PHONE_NUMBER_CONTENT_REQUEST = 'GET_PHONE_NUMBER_CONTENT_REQUEST';
export const GET_PHONE_NUMBER_CONTENT_SUCCESS = 'GET_PHONE_NUMBER_CONTENT_SUCCESS';
export const GET_PHONE_NUMBER_CONTENT_FAILED = 'GET_PHONE_NUMBER_CONTENT_FAILED';

export const GET_WHATSAPP_NUMBER_CONTENT_REQUEST = 'GET_WHATSAPP_NUMBER_CONTENT_REQUEST';
export const GET_WHATSAPP_NUMBER_CONTENT_SUCCESS = 'GET_WHATSAPP_NUMBER_CONTENT_SUCCESS';
export const GET_WHATSAPP_NUMBER_CONTENT_FAILED = 'GET_WHATSAPP_NUMBER_CONTENT_FAILED';
