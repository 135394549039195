export const GET_MY_ALL_MESSAGES_REQUEST = 'GET_MY_ALL_MESSAGES_REQUEST';
export const GET_MY_ALL_MESSAGES_SUCCESS = 'GET_MY_ALL_MESSAGES_SUCCESS';
export const GET_MY_ALL_MESSAGES_FAILED = 'GET_MY_ALL_MESSAGES_FAILED';

export const MARK_MESSAGE_AS_READ_REQUEST = 'MARK_MESSAGE_AS_READ_REQUEST';
export const MARK_MESSAGE_AS_READ_SUCCESS = 'MARK_MESSAGE_AS_READ_SUCCESS';
export const MARK_MESSAGE_AS_READ_FAILED = 'MARK_MESSAGE_AS_READ_FAILED';

export const MARK_MY_ALL_MESSAGES_AS_READ_REQUEST = 'MARK_MY_ALL_MESSAGES_AS_READ_REQUEST';
export const MARK_MY_ALL_MESSAGES_AS_READ_SUCCESS = 'MARK_MY_ALL_MESSAGES_AS_READ_SUCCESS';
export const MARK_MY_ALL_MESSAGES_AS_READ_FAILED = 'MARK_MY_ALL_MESSAGES_AS_READ_FAILED';

export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILED = 'DELETE_MESSAGE_FAILED';
