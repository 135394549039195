export const GET_ALL_PRODUCT_REQUEST = 'GET_ALL_PRODUCT_REQUEST';
export const GET_ALL_PRODUCT_SUCCESS = 'GET_ALL_PRODUCT_SUCCESS';
export const GET_PRODUCT_ID_SUCCESS = 'GET_PRODUCT_ID_SUCCESS';
export const GET_ALL_PRODUCT_FAILED = 'GET_ALL_PRODUCT_FAILED';

export const CREATE_NEW_PRODUCT_REQUEST = 'CREATE_NEW_PRODUCT_REQUEST';
export const CREATE_NEW_PRODUCT_SUCCESS = 'CREATE_NEW_PRODUCT_SUCCESS';
export const CREATE_NEW_PRODUCT_FAILED = 'CREATE_NEW_PRODUCT_FAILED';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';

export const TOGGLE_IS_FEATURE_PRODUCT_REQUEST = 'TOGGLE_IS_FEATURE_PRODUCT_REQUEST';
export const TOGGLE_IS_FEATURE_PRODUCT_SUCCESS = 'TOGGLE_IS_FEATURE_PRODUCT_SUCCESS';
export const TOGGLE_IS_FEATURE_PRODUCT_FAILED = 'TOGGLE_IS_FEATURE_PRODUCT_FAILED';

export const TOGGLE_IS_ENABLED_PRODUCT_REQUEST = 'TOGGLE_IS_ENABLED_PRODUCT_REQUEST';
export const TOGGLE_IS_ENABLED_PRODUCT_SUCCESS = 'TOGGLE_IS_ENABLED_PRODUCT_SUCCESS';
export const TOGGLE_IS_ENABLED_PRODUCT_FAILED = 'TOGGLE_IS_ENABLED_PRODUCT_FAILED';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const CREATE_NEW_PRODUCT_PICTURE_REQUEST = 'CREATE_NEW_PRODUCT_PICTURE_REQUEST';
export const CREATE_NEW_PRODUCT_PICTURE_SUCCESS = 'CREATE_NEW_PRODUCT_PICTURE_SUCCESS';
export const CREATE_NEW_PRODUCT_PICTURE_FAILED = 'CREATE_NEW_PRODUCT_PICTURE_FAILED';

export const GET_ALL_PRODUCT_PICTURE_REQUEST = 'GET_ALL_PRODUCT_PICTURE_REQUEST';
export const GET_ALL_PRODUCT_PICTURE_SUCCESS = 'GET_ALL_PRODUCT_PICTURE_SUCCESS';
export const GET_ALL_PRODUCT_PICTURE_FAILED = 'GET_ALL_PRODUCT_PICTURE_FAILED';

export const UPDATE_PRODUCT_PICTURES_REQUEST = 'UPDATE_PRODUCT_PICTURES_REQUEST';
export const UPDATE_PRODUCT_PICTURES_SUCCESS = 'UPDATE_PRODUCT_PICTURES_SUCCESS';
export const UPDATE_PRODUCT_PICTURES_FAILED = 'UPDATE_PRODUCT_PICTURES_FAILED';

export const DELETE_PRODUCT_PICTURES_REQUEST = 'DELETE_PRODUCT_PICTURES_REQUEST';
export const DELETE_PRODUCT_PICTURES_SUCCESS = 'DELETE_PRODUCT_PICTURES_SUCCESS';
export const DELETE_PRODUCT_PICTURES_FAILED = 'DELETE_PRODUCT_PICTURES_FAILED';