import { authAxios } from 'config/axios';
import { ADD_NEW_LANGUAGE, DELETE_LANGUAGE, GET_ALL_LANGUAGES, TOGGLE_IS_ACCTIVE_LANGUAGE, UPDATE_LANGUAGE } from 'env/server';
import * as actionTypes from './types';
export const successLanguages = (res) => {
    return {
        type: actionTypes.SUCCESS_LANGUGES,
        payload: res
    };
};

export const requestLanguages = () => {
    return {
        type: actionTypes.REQUST_LANGUGES
    };
};
export const errorLanguages = (error) => {
    

    // const message = getErrorMessage(error);
    const message = 'There is error';
    return {
        type: actionTypes.FAILED_LANGUGES,
        payload: message
    };
};
export const getAllLanguages = () => {
    return (dispatch) => {
        dispatch(requestLanguages());
        authAxios
            .get(GET_ALL_LANGUAGES)
            .then((res) => {
                const languages=res.data
                dispatch(successLanguages({languages}))
            })
            .catch((error) => {
                dispatch(errorLanguages(error));

            });
    };
};
export const addNewLanguage = (values,feadBacke) => {
    return (dispatch) => {
        dispatch(requestLanguages());
        authAxios
            .post(ADD_NEW_LANGUAGE,values)
            .then((res) => {
                const languages=res.data
                dispatch(successLanguages({languages}))
                feadBacke()
            })
            .catch((error) => {
                dispatch(errorLanguages(error));

            });
    };
};
export const toggleLanguageActivete = (id) => {
    return (dispatch) => {
        dispatch(requestLanguages());
        authAxios
            .put(`${TOGGLE_IS_ACCTIVE_LANGUAGE}/${id}`)
            .then((res) => {
                const languages=res.data
                dispatch(successLanguages({ languages }));
            })
            .catch((error) => {
                
                dispatch(errorLanguages(error));
            });
    };
};
export const deleteLanguag = (id) => {
    return (dispatch) => {
        dispatch(requestLanguages());
        authAxios
            .delete(`${DELETE_LANGUAGE}/${id}`)
            .then((res) => {
                const languages=res.data
                dispatch(successLanguages({ languages }));
            })
            .catch((error) => {
                
                dispatch(errorLanguages(error));
            });
    };
};
export const editLanguage= (id,values,sucsessFeedback) => {
    return (dispatch) => {
        dispatch(requestLanguages());
        authAxios
            .put(`${UPDATE_LANGUAGE}/${id}`,values)
            .then((res) => {
                const languages=res.data
                dispatch(successLanguages({ languages }));
                sucsessFeedback()
            })
            .catch((error) => {
                
                dispatch(errorLanguages(error));
            });
    };
};
