import * as actionTypes from './types';

const initialState = {
    loading: false,
    error: false,
    messages: []
};
const messagesReducer = (state = { ...initialState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.DELETE_MESSAGE_REQUEST:
        case actionTypes.MARK_MY_ALL_MESSAGES_AS_READ_REQUEST:
        case actionTypes.MARK_MESSAGE_AS_READ_REQUEST:
        case actionTypes.GET_MY_ALL_MESSAGES_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.DELETE_MESSAGE_SUCCESS:
        case actionTypes.MARK_MY_ALL_MESSAGES_AS_READ_SUCCESS:
        case actionTypes.MARK_MESSAGE_AS_READ_SUCCESS:
        case actionTypes.GET_MY_ALL_MESSAGES_SUCCESS: {
            return {
                ...state,
                messages: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.DELETE_MESSAGE_FAILED:
        case actionTypes.MARK_MY_ALL_MESSAGES_AS_READ_FAILED:
        case actionTypes.MARK_MESSAGE_AS_READ_FAILED:
        case actionTypes.GET_MY_ALL_MESSAGES_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default messagesReducer;
