import { createStore, applyMiddleware } from 'redux';
import root from './root';
import { save, load } from 'redux-localstorage-simple';

import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
const initialState = {};
const store = createStore(
    root,
    load({ states:["auth","static"]}),
    composeWithDevTools(applyMiddleware( save({ states:["auth","static"]}), thunk))
);
export default store;

