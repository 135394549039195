import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { Alert, Snackbar } from '@mui/material';

export const toast = function (msg, variant) {
    const Display = withSnackbar(({ message, variant, enqueueSnackbar }) => {
        const [open, setOpen] = React.useState(true);

        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpen(false);
        };

        // enqueueSnackbar(message, { variant });
        return (
            <Snackbar
                open={open}
                autoHideDuration={6000}
                variant={variant}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Alert onClose={handleClose} severity={variant} color={variant} variant="filled" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        );
    });

    const mountPoint = document.getElementById('snackbarhelper');
    ReactDOM.render(
        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Display message={msg} variant={variant} />
        </SnackbarProvider>,
        mountPoint
    );
};

export const showSuccessSnackbarMessage = function (msg) {
    toast(msg, 'success');
};

export const showErrorSnackbarMessage = function (msg) {
    toast(msg, 'error');
};

export const showWarningSnackbarMessage = function (msg) {
    toast(msg, 'warning');
};
