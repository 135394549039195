import * as actionTypes from './types';

const inituelState = {
    loading: false,
    error: false,
    role: null,
    token: '',
    account: {},
    privilege_keys: []
};
const authReducer = (state = { ...inituelState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.REQUST_AUTH:
            return {
                ...state,
                loading: true
            };
        case actionTypes.FAILED_AUTH:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case actionTypes.SUCCESS_AUTH: {
           
            return {
                ...state,
                ...payload,
               
                loading: false,
                error: false
            };
        }

        case actionTypes.SUCCESS_LOGOUT:
            return {
                ...inituelState
            };
        default:
            return {
                ...state
            };
    }
};

export default authReducer;
