import axios from 'axios';
import { showErrorSnackbarMessage, showWarningSnackbarMessage, toast } from '../../snackbarhelper';
import { API_URL } from 'env/server';
import store from 'store/store';

import { getErrorMessageByCode } from 'env/server_errors';

export const authAxios = axios.create();

// for error handling
authAxios.interceptors.response.use(
    (response) => response,
    (error) => {
        let ERROR_CODE = error?.response?.data?.error?.code;
        if (error.message == 'Network Error') {
            showWarningSnackbarMessage(error.message);
        } else {
            const message = getErrorMessageByCode(ERROR_CODE);

            showErrorSnackbarMessage(message);
        }
    }
);
authAxios.interceptors.request.use((config) => {
    config.headers['Authorization'] = `Bearer ${store.getState().auth.token}`;
    return config;
});
