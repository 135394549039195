import dashboard from './dashboard';
import WebsiteContent from './website_content';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, WebsiteContent]
};

export default menuItems;
