// assets
import { IconDashboard, IconSettings } from '@tabler/icons';
import PRIVILEGES from 'env/privileges';

// constant
const icons = { IconDashboard, IconSettings };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    privilege: [PRIVILEGES.SETTINGS_MANAGEMENT],

    children: [
        {
            id: 'settings',
            title: 'Setting',
            type: 'item',
            url: '/settings',
            privilege: [PRIVILEGES.SETTINGS_MANAGEMENT],

            icon: icons.IconSettings,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
