import * as actionTypes from './types';

const initialState = {
    loading: false,
    error: false,
    clients: [],
    client: undefined
};
const clientReducer = (state = { ...initialState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.DELETE_CLIENT_REQUEST:
        case actionTypes.UPDATE_CLIENT_REQUEST:
        case actionTypes.CREATE_NEW_CLIENT_REQUEST:
        case actionTypes.GET_ALL_CLIENTS_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.DELETE_CLIENT_SUCCESS:
        case actionTypes.UPDATE_CLIENT_SUCCESS:
        case actionTypes.CREATE_NEW_CLIENT_SUCCESS:
        case actionTypes.GET_ALL_CLIENTS_SUCCESS: {
            return {
                ...state,
                clients: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.DELETE_CLIENT_FAILED:
        case actionTypes.UPDATE_CLIENT_FAILED:
        case actionTypes.CREATE_NEW_CLIENT_FAILED:
        case actionTypes.GET_ALL_CLIENTS_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }
        case actionTypes.GET_CLIENT_ID_SUCCESS: {
            return {
                ...state,
                client: payload,
                loading: false,
                error: false
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default clientReducer;
