import * as actionTypes from './types';

const initialState = {
    loading: false,
    error: false,
    products: [],
    count: 0,
    product: undefined,
    productPictures: [],
    countPictures: 0
};
const productReducer = (state = { ...initialState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.TOGGLE_IS_ENABLED_PRODUCT_REQUEST:
        case actionTypes.TOGGLE_IS_FEATURE_PRODUCT_REQUEST:
        case actionTypes.DELETE_PRODUCT_REQUEST:
        case actionTypes.UPDATE_PRODUCT_REQUEST:
        case actionTypes.CREATE_NEW_PRODUCT_REQUEST:
        case actionTypes.DELETE_PRODUCT_PICTURES_REQUEST:
        case actionTypes.GET_ALL_PRODUCT_PICTURE_REQUEST:
        case actionTypes.GET_ALL_PRODUCT_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.TOGGLE_IS_ENABLED_PRODUCT_SUCCESS:
        case actionTypes.TOGGLE_IS_FEATURE_PRODUCT_SUCCESS:
        case actionTypes.DELETE_PRODUCT_SUCCESS:
        case actionTypes.UPDATE_PRODUCT_SUCCESS:
        case actionTypes.CREATE_NEW_PRODUCT_SUCCESS:
        case actionTypes.GET_ALL_PRODUCT_SUCCESS: {
            return {
                ...state,
                products: payload.data,
                count: payload.total_items,
                loading: false,
                error: false
            };
        }

        case actionTypes.GET_PRODUCT_ID_SUCCESS: {
            return {
                ...state,
                product: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.TOGGLE_IS_ENABLED_PRODUCT_FAILED:
        case actionTypes.TOGGLE_IS_FEATURE_PRODUCT_FAILED:
        case actionTypes.DELETE_PRODUCT_FAILED:
        case actionTypes.DELETE_PRODUCT_PICTURES_FAILED:
        case actionTypes.UPDATE_PRODUCT_FAILED:
        case actionTypes.CREATE_NEW_PRODUCT_FAILED:
        case actionTypes.GET_ALL_PRODUCT_PICTURE_FAILED:
        case actionTypes.GET_ALL_PRODUCT_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }
        case actionTypes.DELETE_PRODUCT_PICTURES_SUCCESS:
        case actionTypes.GET_ALL_PRODUCT_PICTURE_SUCCESS: {
            return {
                ...state,
                productPictures: payload.data,
                countPictures: payload.total_items,
                loading: false,
                error: false
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default productReducer;
