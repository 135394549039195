const server_errors = [
    { message: 'Session expired ,Login again', code: 501 },
    { message: 'Session expired ,Login again', code: 502 },
    { message: 'Session expired ,Login again', code: 503 },
    { message: 'Session expired ,Login again', code: 504 },
    { message: 'Not allowed action', code: 505 },
    { message: 'Account registered before', code: 1001 },
    { message: 'Account not found', code: 1002 },
    { message: 'Inactive account', code: 1003 },
    { message: 'Invalid email', code: 1004 },
    { message: "password and confirm password don't match", code: 1005 },
    { message: 'invalid password', code: 1006 },
    { message: 'Email or Password not correct', code: 1007 },
    { message: 'Account not verified', code: 1008 },
    { message: 'invalid password', code: 1009 },
    { message: 'Not allowed action', code: 1010 },
    { message: 'Not allowed to delete sales manager account ,he is responsible on a property', code: 1011 },
    { message: 'Not sales manager account', code: 1012 },
    { message: 'Sales manager not found', code: 1013 },
    { message: 'privilege not found', code: 2001 },
    { message: 'privilege already exist', code: 2002 },
    { message: 'role not found', code: 3000 },
    { message: 'role already exist', code: 3001 },
    { message: 'city not found', code: 4001 },
    { message: 'country not found', code: 4002 },
    { message: 'district not found', code: 4003 },
    { message: 'country already exist', code: 4004 },
    { message: 'duplicated code ,there is city has same code', code: 4005 },
    { message: "can't delete this country ,has properties", code: 4006 },
    { message: "can't delete this city ,has properties", code: 4007 },
    { message: "can't delete this district ,has properties", code: 4008 },
    { message: 'language not found', code: 5001 },
    { message: 'language already exist', code: 5002 },
    { message: 'inactive language', code: 5003 },
    { message: 'active language not found', code: 5004 },
    { message: 'property type not found', code: 6001 },
    { message: "can't delete this property type ,there are properties has it", code: 6002 },
    { message: 'property status not found', code: 7001 },
    { message: "can't delete this property status ,there are properties has it", code: 7002 },
    { message: 'property tag not found', code: 8001 },
    { message: "can't delete this property tag ,there are properties has it", code: 8002 },
    { message: 'property feature not found', code: 9001 },
    { message: "can't delete this property feature ,there are properties has it", code: 9002 },
    { message: 'payment plan not found', code: 10001 },
    { message: 'other property details not found', code: 11001 },
    { message: 'other details not found', code: 11002 },
    { message: "can't delete this property details ,there are properties has it", code: 11003 },
    // { message: 'BATHROOMS_MAXIMUM_COUNT_NOT_FOUND', code: 0 },
    // { message: 'BEDROOMS_MAXIMUM_COUNT_NOT_FOUND', code: 0 },
    { message: 'Not allowed file ,Only images and icons', code: 12004 },
    { message: 'currency already exist', code: 13001 },
    { message: 'currency not found', code: 13002 },
    { message: 'price is required', code: 13003 },
    { message: 'property not found', code: 14001 },
    { message: 'content not found', code: 15001 },
    { message: 'service not found', code: 15101 },
    { message: 'image slider not found', code: 15201 },
    { message: 'invalid image slider type', code: 15202 },
    { message: 'settings not found', code: 15301 }
];

const DEFAULT_ERROR_MESSAGE = 'Unknown error';

export const getErrorMessageByCode = (code) => {
    let error = server_errors.find((e) => e.code == code);
    let message = error?.message;
    if (error == null) {
        message = DEFAULT_ERROR_MESSAGE;
    }
    return message;
};
