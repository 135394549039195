export const GET_ALL_CLIENTS_REQUEST = 'GET_ALL_CLIENTS_REQUEST';
export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILED = 'GET_ALL_CLIENTS_FAILED';
export const GET_CLIENT_ID_SUCCESS = 'GET_CLIENT_ID_SUCCESS';

export const CREATE_NEW_CLIENT_REQUEST = 'CREATE_NEW_CLIENT_REQUEST';
export const CREATE_NEW_CLIENT_SUCCESS = 'CREATE_NEW_CLIENT_SUCCESS';
export const CREATE_NEW_CLIENT_FAILED = 'CREATE_NEW_CLIENT_FAILED';

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILED = 'UPDATE_CLIENT_FAILED';

export const DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILED = 'DELETE_CLIENT_FAILED';
