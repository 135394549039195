export const GET_ALL_CATEGORIES_REQUEST = 'GET_ALL_CATEGORIES_REQUEST';
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
export const GET_CATEGORY_ID_SUCCESS = 'GET_CATEGORY_ID_SUCCESS';
export const GET_ALL_CATEGORIES_FAILED = 'GET_ALL_CATEGORIES_FAILED';

export const CREATE_NEW_CATEGORY_REQUEST = 'CREATE_NEW_CATEGORY_REQUEST';
export const CREATE_NEW_CATEGORY_SUCCESS = 'CREATE_NEW_CATEGORY_SUCCESS';
export const CREATE_NEW_CATEGORY_FAILED = 'CREATE_NEW_CATEGORY_FAILED';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED';

export const TOGGLE_IS_FEATURE_CATEGORY_REQUEST = 'TOGGLE_IS_FEATURE_CATEGORY_REQUEST';
export const TOGGLE_IS_FEATURE_CATEGORY_SUCCESS = 'TOGGLE_IS_FEATURE_CATEGORY_SUCCESS';
export const TOGGLE_IS_FEATURE_CATEGORY_FAILED = 'TOGGLE_IS_FEATURE_CATEGORY_FAILED';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED';
