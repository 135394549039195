import axios from 'axios';
import { authAxios } from 'config/axios';
import { LOG_IN_END_POINT, UPDATE_MY_ACCOUNT, RESET_MY_PASSWORD, LOGOUT_END_POINT } from 'env/server';
import { getErrorMessageByCode } from 'env/server_errors';
import { showErrorSnackbarMessage, showWarningSnackbarMessage } from 'snackbarhelper';
import * as actionTypes from './types';
export const successAuth = (res) => {
    return {
        type: actionTypes.SUCCESS_AUTH,
        payload: res
    };
};
export const successLogOut = () => {
    localStorage.setItem("token",'')

    return {
        type: actionTypes.SUCCESS_LOGOUT
    };
};
export const requestAuth = () => {
    return {
        type: actionTypes.REQUST_AUTH
    };
};
export const errorAuth = (error) => {
    

    const message = getErrorMessage(error);
    // const message = 'There is error';
    return {
        type: actionTypes.FAILED_AUTH,
        payload: message
    };
};
export const logIn = (values) => {
    
    return (dispatch) => {
        dispatch(requestAuth());
        axios
            .post(LOG_IN_END_POINT, values)
            .then((res) => {
      
                const { token, account_response } = res.data;
                localStorage.setItem("token", token)
                const role = { account_response };
                const privilege_keys=account_response.role?.privileges?.map(v=>v.key);
               const data = { token, account_response, role, privilege_keys };
                dispatch(successAuth({ ...data }));
            })
            .catch((error) => {
              
                // dispatch(errorAuth(error));

                let ERROR_CODE = error?.response?.data?.error?.code;
                if (error.message == 'Network Error') {
                    showWarningSnackbarMessage(error.message);
                } else {
                    const message = getErrorMessageByCode(ERROR_CODE);

                    showErrorSnackbarMessage(message);
                }

                //////////////to remove/////////////////to remove//////////////
                //////////////to remove/////////////////to remove//////////////
            });
    };
};
export const editMyAccount = (values,onSuccess) => {
    return (dispatch) => {
        dispatch(requestAuth());
        authAxios

            .put(UPDATE_MY_ACCOUNT, values)
            .then((res) => {
                
                const account = res.data;
              
                dispatch(successAuth({ account:{...account} }));
                onSuccess()
            })
            .catch((error) => {
                
                dispatch(errorAuth(error));

                //////////////to remove/////////////////to remove//////////////
                //////////////to remove/////////////////to remove//////////////
            });
    };
};
export const logOut = () => {
    return (dispatch) => {
        dispatch(requestAuth());
        authAxios

            .post(LOGOUT_END_POINT)
            .then((res) => {
                dispatch( successLogOut());

            })
            .catch((error) => {
                dispatch( successLogOut());

                //////////////to remove/////////////////to remove//////////////
                //////////////to remove/////////////////to remove//////////////
            });
    };
};
export const resetMyPassword = (values, sucsessFeedback) => {
    
    return (dispatch) => {
        dispatch(requestAuth());
        authAxios
            .put(`${RESET_MY_PASSWORD}`, values)
            .then((res) => {
                const accounts = res.data;
                dispatch(successAuth({}));
                sucsessFeedback();
            })
            .catch((error) => {
                
                dispatch(errorAuth(error));
            });
    };
};
