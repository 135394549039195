import { combineReducers } from 'redux';
import accountsMnamentReducer from './AccountsMangment/accountsMnamentReducer';
import authReducer from './Auth/authReducer';
import roleReducer from './Role/roleReducer';
import customizationReducer from './Theme/customizationReducer';
import languagesReducer from './Languges/langugesReducer';
import staticReducer from './static/staticReducer';
import contentReducer from './contentManagemet/contentReducer';
import serviceReducer from './serviceManagemet/serviceReducer';
import imageSliderReducer from './imageSliderManagemet/imageSliderReducer';
import messagesReducer from './messagesManagemet/messagesReducer';
import categoriesReducer from './categoriesManagemet/categoryReducer';
import colorsReducer from './colorsManagement/colorReducer';
import productReducer from './productManagemet/productReducer';
import clientReducer from './clientManagemet/clientReducer';

const root = combineReducers({
    auth: authReducer,
    customization: customizationReducer,
    mangmentAccounts: accountsMnamentReducer,
    roleMangment: roleReducer,
    languageseMangment: languagesReducer,
    static: staticReducer,

    content: contentReducer,
    service: serviceReducer,
    image_slider: imageSliderReducer,
    categories: categoriesReducer,
    colors: colorsReducer,
    products: productReducer,
    messages: messagesReducer,
    client: clientReducer
});
export default root;
