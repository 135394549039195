// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const isSideBarItemHasPrivilege = (item, privilege_key) => {
    if (item == null) {
        return false;
    }
    if (!('privilege' in item)) {
        return false;
    }
    const has = item.privilege.includes(privilege_key);
    if (has) {
        return true;
    }
    return isSideBarItemHasPrivilege(item.privilege, privilege_key);
};

const isSideBarItemHasOneOfPrivileges = (item, privilege_keys) => {
    for (let i = 0; i < privilege_keys.length; i++) {
        const has = isSideBarItemHasPrivilege(item, privilege_keys[i]);
        if (has) {
            return true;
        }
    }
    return false;
};

const MenuList = () => {
    const privilege_keys = useSelector((state) => state.auth.privilege_keys);

    const navItems = [];
    menuItem.items.forEach((item) => {
        let push = false;
        if (item.privilege.includes('ANY')) {
            push = true;
        } else {
            push = isSideBarItemHasOneOfPrivileges(item, privilege_keys);
        }

        if (push) {
            const new_item = JSON.parse(JSON.stringify(item));
            new_item.children = [];

            for (let j = 0; j < item.children.length; j++) {
                if (item.children[j].privilege?.includes('ANY')) {
                    new_item.children.push(item.children[j]);
                } else {
                    const push_children = isSideBarItemHasOneOfPrivileges(item.children[j], privilege_keys);
                    if (push_children) {
                        new_item.children.push(item.children[j]);
                    }
                }
            }

            switch (new_item.type) {
                case 'group': {
                    navItems.push(<NavGroup key={new_item.id} item={new_item} />);

                    break;
                }
                default: {
                    navItems.push(
                        <Typography key={new_item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );

                    break;
                }
            }
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
