import { authAxios } from 'config/axios';
import { GET_ALL_PRIVILEGES ,GET_ALL_STATIC_DATA} from 'env/server';
import * as actionTypes from './types';
export const successStatic = (res) => {
    return {
        type: actionTypes.SUCCESS_STATIC,
        payload: res
    };
};

export const requestStatic = () => {
    return {
        type: actionTypes.REQUST_STATIC
    };
};
export const errorStatic = (error) => {
    

    // const message = getErrorMessage(error);
    const message = 'There is error';
    return {
        type: actionTypes.FAILED_STATIC,
        payload: message
    };
};
export const getAllPrivileges = () => {
    return (dispatch) => {
        dispatch(requestStatic());
        authAxios
            .get(GET_ALL_PRIVILEGES)
            .then((res) => {
                const privileges=res.data
                dispatch(successStatic({privileges}))
            })
            .catch((error) => {
                dispatch(errorStatic(error));

                //////////////to remove/////////////////to remove//////////////
                //////////////to remove/////////////////to remove//////////////
            });
    };
};
export const getAllStaticData=()=>{
    return (dispatch) => {
        dispatch(requestStatic());
        authAxios
            .get(GET_ALL_STATIC_DATA)
            .then((res) => {
                const static_data=res.data
                dispatch(successStatic(static_data))
            })
            .catch((error) => {
                dispatch(errorStatic(error));

                //////////////to remove/////////////////to remove//////////////
                //////////////to remove/////////////////to remove//////////////
            });
    };
}
