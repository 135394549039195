import * as actionTypes from './types';

const inituelState = {
    loading: false,
    error: false,
    accounts:null,

};
const accountsMnamentReducer = (state = { ...inituelState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.REQUST_MANGMENT_ACCOUNT:
            return {
                ...state,
                loading: true
            };
        case actionTypes.FAILED_MANGMENT_ACCOUNT:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case actionTypes.SUCCESS_MANGMENT_ACCOUNT:
            return {
                ...state,
                ...payload,
                loading: false,
                error: false
            };

       
        default:
            return {
                ...state
            };
    }
};

export default accountsMnamentReducer;
