// assets
import {
    IconTypography,
    IconPalette,
    IconHome,
    IconShadow,
    IconWindmill,
    IconUsers,
    IconUserPlus,
    IconPhoto,
    IconServer2,
    IconAlignCenter,
    IconCategory,
    IconServer,
    IconFriends,
    IconDeviceMobile,
    IconDeviceMobileMessage
} from '@tabler/icons';
import PRIVILEGES from 'env/privileges';
// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| property MENU ITEMS ||============================== //

const WebsiteContent = {
    id: 'WebsiteContent',
    title: 'Website Content',
    caption: 'Website Content Management',
    privilege: [PRIVILEGES.CONTENT_MANAGEMENT, PRIVILEGES.SERVICE_MANAGEMENT, PRIVILEGES.IMAGE_SLIDER_MANAGEMENT],
    type: 'group',
    children: [
        {
            id: 'services',
            title: 'Services',
            type: 'collapse',
            privilege: [PRIVILEGES.SERVICE_MANAGEMENT],
            icon: IconServer2,

            children: [
                {
                    id: 'all-services',
                    title: 'All Services',
                    type: 'item',
                    url: '/services',
                    icon: IconServer2,
                    privilege: [PRIVILEGES.SERVICE_MANAGEMENT],
                    breadcrumbs: false
                },
                {
                    id: 'add-service',
                    title: 'Add Service',
                    type: 'item',
                    url: '/services/add',
                    icon: IconServer2,
                    privilege: [PRIVILEGES.SERVICE_MANAGEMENT],
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'image-slider',
            title: 'Image Sliders',
            type: 'collapse',

            icon: IconPhoto,
            privilege: [PRIVILEGES.IMAGE_SLIDER_MANAGEMENT],
            children: [
                {
                    id: 'all-image-sliders',
                    title: 'All Image Sliders',
                    type: 'item',
                    url: '/image-sliders',
                    icon: IconPhoto,
                    privilege: [PRIVILEGES.IMAGE_SLIDER_MANAGEMENT],

                    breadcrumbs: false
                },
                {
                    id: 'add-image-slider',
                    title: 'Add Image Slider',
                    type: 'item',
                    url: '/image-sliders/add',
                    icon: IconPhoto,
                    privilege: [PRIVILEGES.IMAGE_SLIDER_MANAGEMENT],

                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'categories',
            title: 'Categories',
            type: 'collapse',
            icon: IconCategory,
            privilege: [PRIVILEGES.CATEGORY_MANAGEMENT],
            children: [
                {
                    id: 'all-categories',
                    title: 'All Categories',
                    type: 'item',
                    url: '/categories',
                    icon: IconCategory,
                    privilege: [PRIVILEGES.CATEGORY_MANAGEMENT],
                    breadcrumbs: false
                },
                {
                    id: 'add-categories',
                    title: 'Add Categories',
                    type: 'item',
                    url: '/categories/add',
                    icon: IconCategory,
                    privilege: [PRIVILEGES.CATEGORY_MANAGEMENT],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'colors',
            title: 'Colors',
            type: 'collapse',
            icon: IconPalette,
            privilege: [PRIVILEGES.COLOR_MANAGEMENT],
            children: [
                {
                    id: 'all-colors',
                    title: 'All Colors',
                    type: 'item',
                    url: '/colors',
                    icon: IconPalette,
                    privilege: [PRIVILEGES.COLOR_MANAGEMENT],
                    breadcrumbs: false
                },
                {
                    id: 'add-colors',
                    title: 'Add Colors',
                    type: 'item',
                    url: '/colors/add',
                    icon: IconPalette,
                    privilege: [PRIVILEGES.COLOR_MANAGEMENT],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'product',
            title: 'Products',
            type: 'collapse',

            icon: IconServer,
            privilege: [PRIVILEGES.PRODUCT_MANAGEMENT],
            children: [
                {
                    id: 'all-product',
                    title: 'All Products',
                    type: 'item',
                    url: '/products',
                    icon: IconServer,
                    privilege: [PRIVILEGES.PRODUCT_MANAGEMENT],

                    breadcrumbs: false
                },
                {
                    id: 'add-product',
                    title: 'Add Product',
                    type: 'item',
                    url: '/products/add',
                    icon: IconServer,
                    privilege: [PRIVILEGES.PRODUCT_MANAGEMENT],

                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'client',
        //     title: 'Clients',
        //     type: 'collapse',

        //     icon: IconFriends,
        //     privilege: [PRIVILEGES.CLIENT_MANAGEMENT],
        //     children: [
        //         {
        //             id: 'all-client',
        //             title: 'All Clients',
        //             type: 'item',
        //             url: '/clients',
        //             icon: IconFriends,
        //             privilege: [PRIVILEGES.CLIENT_MANAGEMENT],

        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'add-client',
        //             title: 'Add Client',
        //             type: 'item',
        //             url: '/clients/add',
        //             icon: IconFriends,
        //             privilege: [PRIVILEGES.CLIENT_MANAGEMENT],

        //             breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: 'general',
            title: 'General',
            type: 'collapse',
            icon: IconAlignCenter,
            privilege: [PRIVILEGES.CONTENT_MANAGEMENT],

            children: [
                {
                    id: 'about-us',
                    title: 'Edit About Us',
                    type: 'item',
                    url: '/about-us/edit',
                    icon: IconAlignCenter,
                    privilege: [PRIVILEGES.CONTENT_MANAGEMENT],

                    breadcrumbs: false
                },
                {
                    id: 'company-profile',
                    title: 'Edit Company Profile',
                    type: 'item',
                    url: '/company-profile/edit',
                    icon: IconAlignCenter,
                    privilege: [PRIVILEGES.COMPANY_PROFILE],

                    breadcrumbs: false
                },
                {
                    id: 'contactUs',
                    title: 'Contact Us',
                    type: 'item',
                    url: '/messages',
                    icon: IconDeviceMobileMessage,
                    privilege: [PRIVILEGES.CONTACT_US]
                }
            ]
        },
    ]
};

export default WebsiteContent;
