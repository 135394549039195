import * as actionTypes from './types';

const initialState = {
    loading: false,
    error: false,
    about_us: {},
    contact_us: {},
    privacy_policy: {},
    cookie_policy: {},
    legal_notices_disclaimer: {},
    terms_use: {},
    company_profile:{}
};
const contentReducer = (state = { ...initialState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.UPDATE_ABOUT_US_CONTENT_REQUEST:
        case actionTypes.GET_ABOUT_US_CONTENT_REQUEST:
        case actionTypes.GET_COMPANY_PROFILE_REQUEST:{
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.UPDATE_ABOUT_US_CONTENT_SUCCESS:
        case actionTypes.GET_ABOUT_US_CONTENT_SUCCESS:{
            return {
                ...state,
                about_us: payload,
                loading: false,
                error: false
            };
        }
        
        case actionTypes.GET_COMPANY_PROFILE_SUCCESS:{
            return {
                ...state,
                company_profile: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.UPDATE_ABOUT_US_CONTENT_FAILED:
        case actionTypes.GET_ABOUT_US_CONTENT_FAILED:
        case actionTypes.GET_COMPANY_PROFILE_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        case actionTypes.UPDATE_CONTACT_US_CONTENT_REQUEST:
        case actionTypes.GET_CONTACT_US_CONTENT_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.UPDATE_CONTACT_US_CONTENT_SUCCESS:
        case actionTypes.GET_CONTACT_US_CONTENT_SUCCESS: {
            return {
                ...state,
                contact_us: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.UPDATE_CONTACT_US_CONTENT_FAILED:
        case actionTypes.GET_CONTACT_US_CONTENT_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        case actionTypes.UPDATE_PRIVACY_POLICY_CONTENT_REQUEST:
        case actionTypes.GET_PRIVACY_POLICY_CONTENT_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.UPDATE_PRIVACY_POLICY_CONTENT_SUCCESS:
        case actionTypes.GET_PRIVACY_POLICY_CONTENT_SUCCESS: {
            return {
                ...state,
                privacy_policy: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.UPDATE_PRIVACY_POLICY_CONTENT_FAILED:
        case actionTypes.GET_PRIVACY_POLICY_CONTENT_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        case actionTypes.UPDATE_COOKIE_POLICY_CONTENT_REQUEST:
        case actionTypes.GET_COOKIE_POLICY_CONTENT_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.UPDATE_COOKIE_POLICY_CONTENT_SUCCESS:
        case actionTypes.GET_COOKIE_POLICY_CONTENT_SUCCESS: {
            return {
                ...state,
                cookie_policy: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.UPDATE_COOKIE_POLICY_CONTENT_FAILED:
        case actionTypes.GET_COOKIE_POLICY_CONTENT_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        case actionTypes.UPDATE_LEGAL_NOTICES_DISCLAIMER_CONTENT_REQUEST:
        case actionTypes.GET_LEGAL_NOTICES_DISCLAIMER_CONTENT_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.UPDATE_LEGAL_NOTICES_DISCLAIMER_CONTENT_SUCCESS:
        case actionTypes.GET_LEGAL_NOTICES_DISCLAIMER_CONTENT_SUCCESS: {
            return {
                ...state,
                legal_notices_disclaimer: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.UPDATE_LEGAL_NOTICES_DISCLAIMER_CONTENT_FAILED:
        case actionTypes.GET_LEGAL_NOTICES_DISCLAIMER_CONTENT_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        case actionTypes.UPDATE_TERMS_USE_CONTENT_REQUEST:
        case actionTypes.GET_TERMS_USE_CONTENT_REQUEST: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }
        case actionTypes.UPDATE_TERMS_USE_CONTENT_SUCCESS:
        case actionTypes.GET_TERMS_USE_CONTENT_SUCCESS: {
            return {
                ...state,
                terms_use: payload,
                loading: false,
                error: false
            };
        }
        case actionTypes.UPDATE_TERMS_USE_CONTENT_FAILED:
        case actionTypes.GET_TERMS_USE_CONTENT_FAILED: {
            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default contentReducer;
