import { useLocation, useRoutes } from 'react-router-dom';

// routes

import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import {useDispatch, useSelector} from 'react-redux';
import { getAllRoles ,getAllLanguages,getAllStaticData} from 'store';
import { useEffect } from 'react';

// ==============================|| ROUTING RENDER ||============================== //
import store from "store/store";
export default function ThemeRoutes() {
    let location = useLocation();

    const authStore=useSelector(state=>state.auth)
    const {token}=authStore
    const dispatch =useDispatch()
    useEffect(()=>{
        if(token!==store.getState().auth.token){
            location.reload();
         

        }
      

      
        dispatch(getAllStaticData())
    },[location])
    return useRoutes([MainRoutes, AuthenticationRoutes]);
}
