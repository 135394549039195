const PRIVILEGES = {
    ACCOUNT_MANAGEMENT: 'account_management',
    LANGUAGE_MANAGEMENT: 'language_management',
    ROLE_MANAGEMENT: 'role_management',
    SALES_MANAGEMENT: 'sales_management',
    SERVICE_MANAGEMENT: 'service_management',
    CURRENCY_MANAGEMENT: 'currency_management',
    ABOUT_US_MANAGEMENT: 'about_us_management',
    CONTENT_MANAGEMENT: 'content_management',
    IMAGE_SLIDER_MANAGEMENT: 'image_slider_management',
    CATEGORY_MANAGEMENT: 'category_management',
    COLOR_MANAGEMENT: 'color_management',
    PRODUCT_MANAGEMENT: 'product_management',
    CLIENT_MANAGEMENT: 'client_management',
    COMPANY_PROFILE: "company-profile",
    CONTACT_US: "CONTACT_US",
    VIEW_COLOR:"VIEW_COLOR"
};

export default PRIVILEGES;
