import * as actionTypes from './types';

const inituelState = {
    loading: false,
    error: false,
    
    roles: []
};
const roleReducer = (state = { ...inituelState }, action) => {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.REQUST_ROLE:
            return {
                ...state,
                error:null,
                loading: true
            };
        case actionTypes.FAILED_ROLE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case actionTypes.SUCCESS_ROLE:
            return {
                ...state,
                ...payload,
                loading: false,
                error: false
            };

      
        default:
            return {
                ...state
            };
    }
};

export default roleReducer;
